/* ###########
* CARD DESIGN
########## */

.card {
    /* background: #fff; */
    padding: 0;
    border: 0;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.category {
    padding: .5rem .75rem;
    font-size: .625rem;
    line-height: normal;
    border-radius: 999px;
    padding: .25rem .5rem;
    background-color: rgba(0, 0, 0, 0.1);
}

.body_top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
}

.gradient_azure {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    height: 200px;
}

.moderate_idea {
    background-image: linear-gradient(120deg, #b1bbb5 0%, #8fd3f4 100%);
    height: 200px;
}

/* ###########
* FULL PAGE CARDS
########## */

.cover_300 {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.cover_200 {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}

.scrim {
    background: rgb(0, 0, 0);
    // background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.15) 33%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.15) 50%, rgba(0, 0, 0, 0) 100%);
    z-index: 50;
}

.text_shadow {
    color: white;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    z-index: 60;
}


/* ###########
* HOVER ANIMATIONS
########## */

.card_clickable:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-info {
    box-sizing: border-box;
    position: absolute;
    z-index: 95; /* en dessous du bouton d'ajout d'idée (99) */
    opacity: 0;
    text-align: center;
    transition: opacity 0.2s cubic-bezier(0.33, 0.66, 0.66, 1);
}

.card_clickable:hover .card-info {
    opacity: 1;
}


// Puces plus petites

.card ul {
    list-style: none;
}

.card li::before {
    content: "•"; 
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
}