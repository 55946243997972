.content {
    max-width: 100%;
}

.container{
    margin-top: 2vh;

}

.counterrr {
  border-style: solid;
  border-color: black;
  background-color: #FEEFBD;
  position: relative;
  padding-top: 1%;
  padding-bottom: 1%;  
  padding-right: 4%;
  padding-left: 4%;
  border-radius: 4%/12%;
  margin-top:1.5%
}

.count {
  height: 98.5%;
  width: 17.5%;
  background-color:black;
  border-color:black;
  border-style: solid;
  margin-right: 2.5%;
  float: left;
}

.numberr{
    color: white;
    font-size: 7.33vw;
    text-align: center;
    vertical-align: middle;
}

.bloc{
    display: flex;
}

.noms{
    text-align: center;
    font-size: 2.5vw;
    margin-bottom: 0%;
}

.countIcons{
    max-width: 15.5vw; 
    max-height:12.5vh;
}

.col {
    padding-top:2%;
}

.row {
    margin-left: auto;
    margin-right: auto;
}

#img img{
    display:block; 
    margin:auto;
}
