@import "_colors";
@import "_custom_variables";  

/* Bootstrap modifications */
// voir nouvelles couleurs de twitter

// the ~ allows you to reference things in node_modules 
@import "~bootstrap/scss/bootstrap";
@import "toastr";


@import "_layout";
@import "_forms";
@import "_buttons";
@import "_fonts";
@import "_cards";
@import "counter";

body {
	font-family: $font_main;
    color: $default-text-color;
    padding: 54px 0 0 0;
    font-weight: 300;
}

.font_logo {
	font-family: $font_logo;
}

.full_page_container {
    width: 100vw;
    min-height: calc(100vh - 54px);
}


/* ###########
* PANELS
########## */

.panel {
	position: fixed;
	top: 0;
	height: 100vh;
}

.panel_gauche {
	left: 0;
	width: 50%;
}

.panel_droite {
	right: 0;
	width: 50%;
}

.counter {
    position: fixed;
    top: 100px;
    right: 70px;
    z-index: 99;
    background-color: #fff;
    border-radius: 12px;
    padding: 16px;
    box-shadow: 0 0 4px rgba(0,0,0,.14), 0 4px 8px rgba(0,0,0,.28);
    transition: all 500ms ease;
    text-align: center;
    font-size: 36px;
    /* disparaît sur les petits écrans */
}

.alert small.error {
    font-size: inherit;
    font-weight: 300;
}

.hr_thin {
    margin: 0;
}

.no_style, .hidden_link, .hidden_link:hover {
    text-decoration: inherit;
    color: inherit;
}

.cliquable {
    cursor: pointer;
}

.full_page_img {
    width: 100%;
    height: calc(100vh - 54px);
    // height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position-y: top;
}

.animated_gradient {
    color: #fff;
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
	background-size: 400% 400%;
	-webkit-animation: Gradient 15s ease infinite;
	-moz-animation: Gradient 15s ease infinite;
	animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@-moz-keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}

@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}


/* BREAKPOINT MD */

@media (max-width: 768px) {

    .idee_liste_vote .grid-item, .idee_liste_propulse .grid-item {
        width: 100%;
    }

    .idee .titre_idee {
        font-size: 1.5rem;
    }

    .button_fixed {
        bottom: 15px;
        right: 15px;
        min-width: 70px;
        min-height: 70px;
        padding: 16px;
    }

    .counter {
        display: none;
    }

}

/* ###########
* ELEVATION
########## */

.elevation_1dp {
    -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.elevation_1dp:hover { /* 6dp on hover */
    -webkit-box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);
}


.dropdown-item {
    font-weight: inherit;
}


/* ###########
* Skeleton
########## */

.jumbotron {
    z-index: 60; /* sous la navbar à 1030 */
}
.skeleton {
    background: #F8F8FA;
    height: 100vh;
    width: 100vw;
    z-index: 50; /* sous la navbar à 1030 */
    position: fixed;
    top: 0;
    left: 0;
}


/* ###########
* TESTS GRADIENTS
########## */

.bg_1 {
        background-image: linear-gradient(120deg, #02fdee 0%, #548bed 100%);
}
.bg_2 {
    background-image: linear-gradient(120deg, #f1fa58 0%, #e8a96a 100%);
}
.bg_3 {
    background-image: linear-gradient(120deg, #18ec9d 0%, #1ad24e 100%);
}
.bg_4 {
    background-image: linear-gradient(120deg, #e684bc 0%, #ffa1c0 100%);
}
.bg_5 {
    background-image: linear-gradient(120deg, #e7ec6d 0%, #f93b2f 100%);
}


/* ###########
* ROOM LANDING
########## */

/* BREAKPOINT MD */
@media (max-width: 768px) {

    .idee_liste_vote .grid-item, .idee_liste_propulse .grid-item {
        width: 100%;
    }

    .idee .titre_idee {
        font-size: 1.5rem;
    }

    .button_fixed {
        bottom: 15px;
        right: 15px;
        min-width: 70px;
        min-height: 70px;
        padding: 16px;
    }

    .counter {
        display: none;
    }

}

.font_weight_300 { // déprécié, utiliser fw_300
    font-weight: 300;
}

.font_weight_600 { // déprécié, utiliser fw_600
    font-weight: 600;
}

.profil_page {
    padding: 0rem;
}

.bg_darken {
    background-color: rgba(0, 0, 0, 0.1);
}

.rounded_bl {
    border-bottom-left-radius: 4px;
    border: none;
}

.vich-image img {
    max-width: 180px; 
    // à rendre responsive
    margin-right: 20px;
}

/* ##################
* NOTATION PAGE IFCAM
################## */

.circle-fill-difficulty {
    position: relative;
    width: 7%;
    height: 0;
    padding: 3.5% 0;
    border-radius: 50%;
    border: 1px solid #404040;
    background-color: #404040; 
}

.circle-fill-essential {
    position: relative;
    width: 8%;
    height: 0;
    padding: 4% 0;
    border-radius: 50%;
    border: 1px solid #404040;
    background-color: #404040; 
}

.circle-empty-difficulty {
    position: relative;
    width: 7%;
    height: 0;
    padding: 3.5% 0;
    border-radius: 50%;
    border: 1px solid #404040;
    background-color: white; 
}

.filled {
    font-size: 2vw;
    color: white;
    text-align: center;
    line-height: 0.2vw;
    display: block;
}

.logo div {
    position: absolute;
    align-items: center;
}

.logo .c1 {
    margin-left: -6%;
    z-index: 5;
}

.logo .c2 {
    margin-left: 0%;
    z-index: 10;
}

.logo .c3 {
    margin-left: 6%;
    z-index: 15;
}

.logo .c4 {
    margin-left: 12%;
    z-index: 20;
}

.logo .c1bis {
    margin-left: 30%;
    z-index: 5;
}

.logo .c2bis {
    margin-left: 36%;
    z-index: 10;
}

.logo .c3bis {
    margin-left: 42%;
    z-index: 15;
}

.logo .c4bis {
    margin-left: 48%;
    z-index: 20;
}

/* ##################
* FIN NOTATION IFCAM
################## */


// INPUT FICHIER CHANGEMENT DU LABEL "BROWSE" EN "PARCOURIR"

.custom-file-input ~ .custom-file-label::after {
    content: "Parcourir";
}

// BARRE FILTRE CATEGORIES DANS MODULE/SHOW

.scrolling-wrapper .btn {
    width: 30%;
    height: 100%;
    background: black;
    display: inline-block;
}

.scrolling-wrapper {
    height: 10%;
    margin-bottom: 3%;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}

// FIL D'ACTUALITES DANS LE BACK OFFICE ROOM 

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.bg-forum {
    background-color: #F2F2F3;
    border-radius: 5px;
    border-left: 5px solid #042132;
}

#form_comment > div > .btn-secondary {
    background-color: #a6c!important;
    border-color: #a6c!important;
}


// FORUM

.bg_forum {
    background-color: #F8F8FA;
    border-radius: 5px;
    border-left: 5px solid #042132;
}

// Filtres commentaires back office room EDIT

ul.filters {
    border-bottom: none;
}

.filters > li > a.active {
    background: transparent;
    color: #f03d6c !important; 
    box-shadow: inset 0 -2px 0 #f03d6c !important;
}

.filters > li > a {
    display: inline-block;
    padding: 0 1em;
    width: auto;
    color: #9a9a9a;
    border: none;
    border-color: transparent!important;
    background-color: transparent!important;
}

//

.hr-text {
    line-height: 1em;
    position: relative;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    height: 1.5em;
    opacity: .5;
    &:before {
      content: '';
      // use the linear-gradient for the fading effect
      // use a solid background color for a solid bar
      background: linear-gradient(to right, transparent, #818078, transparent);
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1px;
    }
    &:after {
      content: attr(data-content);
      position: relative;
      display: inline-block;
      color: black;
  
      padding: 0 .5em;
      line-height: 1.5em;
      // this is really the only tricky part, you need to specify the background color of the container element...
      color: #818078;
      background-color: #fcfcfa;
    }
  }


#nav-search{
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button {
        display: none; 
    }

    input{
        outline: none;
    
        -webkit-appearance: textfield;
        -webkit-box-sizing: content-box;
        font-family: inherit;
        font-size: 100%;
        
        background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
        border: solid 1px #ccc;
        padding: 9px 10px 9px 32px;
        width: 55px;
        
        -webkit-border-radius: 10em;
        -moz-border-radius: 10em;
        border-radius: 10em;
        
        -webkit-transition: all .5s;
        -moz-transition: all .5s;
        transition: all .5s;
    }

    input:focus {
        width: 130px;
        background-color: #fff;
        border-color: #66CC75;
        
        -webkit-box-shadow: 0 0 5px rgba(109,207,246,.5);
        -moz-box-shadow: 0 0 5px rgba(109,207,246,.5);
        box-shadow: 0 0 5px rgba(109,207,246,.5);
    }
    
    input:-moz-placeholder {
        color: #999;
    }

    input::-webkit-input-placeholder {
        color: #999;
    }
}


  